import React from "react";
import {
  Input,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { IconSearch } from "../../Components/Icons";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  GET_FILTER_PRODUCT,
  SET_PRODUCT_DETAIL_ID,
  TOGGLE_MODAL_PRODUCT_DETAIL,
} from "../../store/actions/products";
import {
  LIST_PRODUCT,
} from "../../store/actions/cart";
import { toast } from "react-toastify";

const mapStateToProps = (state) => {
  return {
    searchData: state.Cart.searchData,
    selectedWarehouse: state.Cart.selectedWarehouse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GET_FILTER_PRODUCT: () => dispatch(GET_FILTER_PRODUCT()),
       LIST_PRODUCT: (lang, userId) => dispatch(LIST_PRODUCT("en", this.props.userId)),
    TOGGLE_MODAL_PRODUCT_DETAIL: () => dispatch(TOGGLE_MODAL_PRODUCT_DETAIL()),
    SET_PRODUCT_DETAIL_ID: (payload) =>
      dispatch(SET_PRODUCT_DETAIL_ID(payload)),
    SELECT_WAREHOUSE: (payload) =>
      dispatch({
        type: "SELECT_WAREHOUSE",
        selectedWarehouse: payload,
      }),
  };
};
class AutoCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: "",
      finalArray: [],
      isSearchFocused: false,
      warehouseDropdownOpen: false,
    };
    this.handleClickForFocus = this.handleClickForFocus.bind(this);
  }

  handleClickForFocus(e) {
    let contains = false;

    const searchboxes = document.querySelectorAll(".top-search-box");
    for (let i = 0; i < searchboxes.length; i++) {
      if (searchboxes[i].contains(e.target)) {
        contains = true;
        break;
      }
    }

    this.setState({
      isSearchFocused: contains,
    });
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickForFocus);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickForFocus);
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  onInputFocus = () => {
    const { filterProductItem } = this.props;
    if (!filterProductItem || filterProductItem.length === 0) {
      this.props.GET_FILTER_PRODUCT();
    }
  };
  onTextChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    let regex = /^[0-9]+$/;
    // console.log(this.props.filterProductItem);
    if (value.match(regex) && value.length >= 3) {
      let filterData = (this.props.filterProductItem ?? []).filter((item) => {
        const firstCondition =
          item.node.matchCode.includes(value) &&
          item.node.dots &&
          item.node.dots.length > 0;

        if (!firstCondition) return false;
        for (const dot of item.node.dots) {
          if (dot.stock > 0) return true;
          // if(localStorage.getItem('stockWarehouse')){
          //   var sj_warehouse = "stockWarehouse" + localStorage.getItem('stockWarehouse');
          //   if (dot[sj_warehouse] > 0) return true;
          // }
          // else{
          //   if (dot.stock > 0) return true;
          // }
         
        }
      });

      let sortData = filterData.sort((a, b) => {
        if (a.node.loadRating < b.node.loadRating) {
          return -1;
        }
        if (a.node.loadRating > b.node.loadRating) {
          return 1;
        }
        return 0;
      });

      const match = sortData.map((o) => o.node.matchCode);
      const filtered = sortData.filter((item, index) => {
        let code = item.node.matchCode;
        return !match.includes(code, index + 1);
      });

      // let uniqueData = this.getUniqueListBy(sortData, "matchCode")
      // console.log(filtered);
      // debugger
      suggestions = filtered;
    }

    this.renderSuggestions(suggestions);
    this.setState(() => ({
      text: value,
    }));
    this.props.setValue("searchFilter", value);
  };

  selectedText(value) {
    let searchValue = value.node.matchCode;
    // console.log(searchValue);
    // let forSearch = this.state.text
    this.setState(() => ({
      text: "",
      finalArray: [],
    }));
    this.props.setValue("searchFilter", searchValue);
    this.props.onSelectSearch(searchValue);
    this.props.TOGGLE_MODAL_PRODUCT_DETAIL();
    this.props.SET_PRODUCT_DETAIL_ID(value.node.id);
  }

  renderSuggestions = (suggestions) => {
    if (suggestions.length) {
      this.setState({
        finalArray: suggestions,
      });
    }
  };

  groupBy(list, keyGetter) {
    const map = new Map();
    let collection = [];
    list.forEach((item) => {
      const key = keyGetter(item);
      collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  onSearch = () => {
    this.props.onSearch();
    this.setState(() => ({
      finalArray: [],
    }));
  };

  searchFunction = () => {
    if (
      this.props.selectedWarehouse === null ||
      this.props.selectedWarehouse === "Warehouse" ||
      this.props.selectedWarehouse === "" ||
      this.props.selectedWarehouse === undefined
    ) {
      toast.error(this.props.t("topHeader.pleaseSelectAWarehouse"));
    } else {
      this.onSearch();
      this.setState({ text: "" });
    }
  };

  render() {
    const { text } = this.state;
    const { t } = this.props;
    return (
      <div id="notebooks">
        <div className="dropdown-and-search-container">
          <select
            className="dropdown warehouse-dropdown"
            onChange={(e) => {
              localStorage.setItem("stockWarehouse",e.target.value);
              this.props.SELECT_WAREHOUSE(
                e.target.value === "Warehouse" ? undefined : e.target.value
              );
               this.onSearch();
              //  this.setState({ text: "" });
              
            }}
          >
            <option
              value={undefined}
              selected={this.props.selectedWarehouse === undefined}
            >
              Warehouse
            </option>
            {[
              {
                id: 1,
                name: "Fujairah",
              },
              {
                id: 2,
                name: "Abudhabi",
              },
              {
                id: 3,
                name: "Dubai",
              },
              {
                id: 4,
                name: "Alain",
              },
            ].map((item, index) => (
              <option
                key={index}
                value={item.id}
                selected={this.props.selectedWarehouse === item.id}
              >
                {item.name}
              </option>
            ))}
          </select>
          <Input
            id="query"
            type="tel"
            autoComplete="off"
            onFocus={() => {
              this.onInputFocus();
            }}
            onChange={this.onTextChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.searchFunction();
              }
            }}
            value={text}
            placeholder={t("topHeader.productSearch")}
          />
        </div>
        <Button
          color="primary"
          className="search-btn rounded-pill"
          onClick={() => {
            this.searchFunction();
          }}
        >
          <IconSearch />
        </Button>
        {this.state.isSearchFocused && this.state.finalArray.length > 0 && (
          <ul className="suggestions">
            {this.state.finalArray.map((item, index) => (
              <li
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.props.selectedWarehouse === null ||
                    this.props.selectedWarehouse === "Warehouse" ||
                    this.props.selectedWarehouse === "" ||
                    this.props.selectedWarehouse === undefined
                  ) {
                    toast.error(
                      this.props.t("topHeader.pleaseSelectAWarehouse")
                    );
                  } else {
                    this.selectedText(item);
                    this.setState({ text: "" });
                  }
                }}
              >
                {item.node.matchCode}
              </li>
            ))}
          </ul>
        )}
        {/* <span>Suggestions: {suggestions.length}</span> */}
      </div>
    );
  }
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AutoCompleted)
);
